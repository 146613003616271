/* Override angular material variables */
html {
  --mat-paginator-container-background-color: initial;
  --mat-tab-header-divider-height: 1px;
  --mat-tab-header-divider-color: #0091ad;
  --mat-table-row-item-container-height: 44px;

  --mdc-checkbox-selected-focus-icon-color: #0091ad;
  --mdc-checkbox-selected-hover-icon-color: #0091ad;
  --mdc-checkbox-selected-icon-color: #0091ad;
  --mdc-checkbox-selected-focus-state-layer-color: #0091ad;
  --mdc-checkbox-selected-hover-state-layer-color: #0091ad;
  --mdc-checkbox-selected-pressed-state-layer-color: #0091ad;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mat-tab-header-inactive-label-text-color: #000;
  --mat-tab-header-active-label-text-color: #fff;
  --mat-tab-header-active-hover-label-text-color: initial;
  --mat-tab-header-active-focus-label-text-color: #fff;
}
.mat-mdc-tab-header {
  --mat-tab-header-label-text-weight: bold;
}
/* Override mat-tag-group styles */
.mat-mdc-tab.mdc-tab--active {
  background-color: #0091ad;
}
.mdc-tab-indicator {
  display: none !important;
}

/* mat-form-field appearance=outline outline color */
.mat-mdc-form-field {
  padding: 0;
  border: 0;
}
.mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background-color: #fff;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: #0091ad !important;
}
