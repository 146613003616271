@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
body {
  font-size: 13px;
  line-height: 1.5;
  box-sizing: border-box;
  margin: 0;
  font-family: "Outfit", sans-serif;
}

h1,
h2,
h3 {
  font-family: "Outfit", sans-serif;
}

.txt-blue,
.txt-blue span {
  color: #0091ad;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

thead.table-cus-bg tr th {
  background: #77797a !important;
  color: #fff;
}

table.table-vertical-border tr td {
  border: 1px solid #d9d9d9 !important;
}

/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
a {
  color: var(--pink-color);
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

a:focus {
  outline: none;
}

a:hover,
a:active {
  outline: 0;
}

.plr10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

/*--------------------------------------------------------------
## Sites buttons
--------------------------------------------------------------*/
.btn-regular-blue {
  background: #75bfe8;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.btn-regular-blue:hover {
  background-color: #62758d;
}

.btn-regular-gray {
  background: #9fa2a4;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  padding: 6px 8px;
  display: block;
}

.btn-regular-gray:hover {
  background-color: #858889;
}

.btn-regular-green {
  background: #33cc99;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  padding: 6px 8px;
  display: block;
}

.btn-regular-green:hover {
  background-color: #23af81;
}

.btn-login,
.btn-submit {
  background: #0091ad;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  padding: 8px 0;
  color: #fff;
}

.btn-login:hover,
.btn-submit:hover {
  background: #62758d;
  color: #fff;
}

.btn-cancel {
  background: #9d9e9e;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  padding: 8px 0;
  color: #fff;
  border: none;
  font-size: 18px;
}

.btn-cancel:hover {
  background: #777777;
  color: #fff;
}

.btn-darkGreen {
  background: #0091ad;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  padding: 8px 0;
  color: #fff;
  border: none;
  font-size: 18px;
}

.btn-darkGreen:hover {
  background: #62758d;
  color: #fff;
}

/*--------------------------------------------------------------
## Page wraper style start
--------------------------------------------------------------*/
/* #dash-wrap {
  display: flex;
  margin: 0 20px;
} */

.sideBar {
  position: fixed;
  width: 98px;
  background: #fafafa;
  height: 100vh;
}

.content-wrap {
  height: 100vh;
  width: 100%;
  margin-left: auto;
}

/* Side nav on big screen style */
ul.nav .nav-item {
  padding: 10px 0;
}

ul.nav .nav-item a span {
  display: block;
  font-size: 13px;
  line-height: 13px;
  margin-top: 5px;
}

ul.nav .nav-item a {
  color: #000;
  font-size: 12px;
}

/* ul.nav .nav-item a.active{
  position: relative;
}
ul.nav .nav-item a.active::after{
  position: absolute;
  content: '';
  height: 1px;
  width: 35px;
  background: #33CC99;
  bottom: 0;
  margin-left: -50%;
  transform: translateX(50%);
} */

ul.nav .nav-item a:hover {
  color: #33cc99;
}

ul.nav .nav-item a.active span {
  color: #33cc99;
}

/* Responsive Nav */
.nav-phn {
  display: none;
}

.nav-phn img.phn-toggle {
  width: 26px;
  cursor: pointer;
}

/* =============== Top header screen style =========== */
header {
  padding: 10px 20px;
}

header img.qwrap-logo {
  max-width: 250px;
}

.lgaq-logo img {
  width: 320px;
}

.welcome-name p {
  font-size: 16px;
  margin-bottom: 0;
  color: #0091ad;
}

.welcome-name p span {
  color: #000;
}

.logout {
  position: relative;
}

.logout::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 16px;
  background: #d9d9d9;
  left: -7px;
  top: 4px;
}

.logout a {
  font-size: 16px;
  color: #0091ad;
}

.refresh-wrap .text-side {
  background: #f9f9f9;
  padding: 6px;
  border-radius: 3px 0 0 3px;
  height: 33px;
  width: 239px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -ms-border-radius: 3px 0 0 3px;
  -o-border-radius: 3px 0 0 3px;
}

.refresh-wrap .text-side input {
  font-size: 13px;
  border: none;
  background: #f9f9f9;
}

.refresh-wrap .text-side input:focus {
  outline: none;
  border: none;
}

.refresh-wrap .refresh-btn button {
  background: #0091ad;
  border: none;
  padding: 7px;
  border-radius: 0 3px 3px 0;
  height: 33px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.refresh-wrap .refresh-btn button i {
  color: var(--white-color);
  font-size: 16px;
}

/* =============== Page content div style =========== */
.page-content {
  overflow: hidden;
  padding: 20px;
}

h2.home-title {
  font-size: 26px;
  background: #77797a;
  padding: 10px 15px;
  color: #fff;
  margin: 0;
}

.home-table-contain {
  border: 1px solid #77797a;
  padding: 15px;
}

.home-table-contain .refresh-wrap .text-side {
  background: #ebebeb;
  padding: 10px;
  border-radius: 3px;
}

.home-table-contain .refresh-wrap .text-side input {
  font-size: 13px;
  border: none;
  background: #ebebeb;
}

.home-table-contain .refresh-wrap .text-side input:focus {
  outline: none;
  border: none;
}

.home-table-contain .refresh-wrap .refresh-btn button {
  background: #77797a;
  border: none;
  padding: 10px;
  border-radius: 0 3px 3px 0;
}

.home-table-contain .refresh-wrap .refresh-btn button i {
  color: var(--white-color);
  font-size: 16px;
}

/* Switch button style */
.onoffswitch {
  position: relative;
  width: 90px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "Active";
  padding-left: 10px;
  background-color: #33cc99;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "Inactive";
  padding-right: 6px;
  background-color: #dead4d;
  color: #fff;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 6px;
  height: 18px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 56px;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

button.edit-btn {
  font-size: 14px;
  border-radius: 4px;
  background: #0091ad;
  padding: 6px 10px;
  border: none;
  color: #fff;
}

button.edit-btn:hover {
  background: #62758d;
}

/* Pagination style */
.pagination-wrap {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.pagination-wrap ul.pagination {
  margin: 0;
  border-radius: 2px;
}

.pagination-wrap ul.pagination li .page-link {
  background: #f1f1f1;
}

.pagination-wrap ul.pagination li .active {
  background: #75bfe8;
  border: 1px solid #75bfe8;
}

/* ============= Login register forgot screen style ========== */
.bg-wrap {
  background: url(../assets/images/QWRAP_Logo-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 -236px;
  height: 100vh;
  overflow-x: hidden;
}

header.login-hdr {
  border-top: 10px solid;
  border-color: #0091ad;
  text-align: center;
}

.welcome-wrap {
  max-width: 925px;
  width: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 20px;
  margin: 0 auto;
}

.welcome-wrap h3 {
  font-size: 22px;
  color: #0091ad;
  text-align: center;
}

.welcome-wrap p {
  font-size: 18px;
  color: var(--CFEP-Blue-color);
  text-align: center;
}

.welcome-wrap form {
  max-width: 510px;
  width: 100%;
  margin: 0 auto;
}

.welcome-wrap .form-check-label {
  position: relative;
  left: 10px;
  top: 5px;
}

.extra-box {
  max-width: 925px;
  width: 100%;
  background: var(--CFEP-Stone-color);
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
  margin: 30px auto;
}

.extra-box span {
  font-size: 18px;
  color: #626060;
}

.extra-box span a {
  color: #33cc99;
}

.extra-box span a:hover {
  color: #0091ad;
  text-decoration: underline;
}

label a {
  color: #0091ad;
}

/* ============= Setup 2FA page style ========== */
.welcome-wrap .inner-box {
  border: 1px solid #dedede;
  background: #f5f5f5;
  padding: 20px;
  text-align: center;
}

.welcome-wrap .inner-box span {
  margin-bottom: 12px;
  display: block;
}

.welcome-wrap .inner-box input {
  background: #fff;
}

.welcome-wrap .inner-box h4 {
  font-size: 18px;
  text-align: left;
}

/*--------------------------------------------------------------
## Bidpool Application And Regiona Application style start
--------------------------------------------------------------*/
.bid-app-no h2 {
  font-weight: 700 !important;
  color: #0e3342;
  margin: 0;
}

.saved-btn a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 11px;
  background: #cbfeed;
  border-radius: 4px;
}

.saved-btn a:hover {
  background: #a3e1cd;
}

.saving-btn a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 11px;
  background: #e5f6ff;
  border-radius: 4px;
}

.saving-btn a:hover {
  background: #b0cbda;
}

.bid-btn span {
  font-size: 16px;
}

.bid-btn img {
  width: 100%;
  max-width: 24px;
}

.bidpool-top-main {
  margin-bottom: 20px;
}

.under-main-menu {
  background: #eaeaea;
  padding: 17px 23px;
}

.under-main-items {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.under-main-items a {
  padding: 9px 9px;
}

.under-main-items a:hover {
  background: #fdfdfd;
  padding: 9px 9px;
}

.bid-menu-item {
  font-size: 16px;
  font-weight: 500;
}

.under-pool-form {
  border: 1px solid #a5a5a5;
  padding: 25px 30px;
}

.under-check .form-check-label {
  font-size: 14px;
}

.under-check .form-check {
  margin-bottom: 12px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

.under-check .form-check .form-check-input {
  width: 24px;
  height: 24px;
}

.main-contact-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-contact-header {
  background: #eaeaea;
  padding: 10px 22px;
}

.main-contact-heading h2 {
  font-size: 16px;
  margin: 0;
  font-weight: 400;
}

.main-contact-main {
  border: 1px solid #a5a5a5;
}

.under-check {
  margin-bottom: 33px;
}

.main-contact-form {
  padding: 31px 22px;
}

.form-field-cst {
  gap: 17px;
}

.form-field-cst .form-group {
  max-width: unset;
  width: 100%;
}

.form-group.del-btn {
  position: absolute;
  right: 0;
  max-width: unset;
  width: auto;
}

.form-field-cst {
  position: relative;
}

.form-field-cst {
  padding-right: 15px;
}

.form-group.del-btn {
  position: absolute;
  right: -15px;
  max-width: unset;
  width: auto;
  bottom: 0px;
}

.form-field-cst .form-group label {
  font-size: 14px;
  margin-bottom: 10px;
  color: #000000;
  white-space: nowrap;
}

.form-control {
  font-size: 13px;
  padding: 10px 12px;
  border-color: #979494;
  border-radius: 3px;
}

.delete-btn img {
  width: 100%;
}

.delete-btn {
  display: inline-flex;
  max-width: 24px;
  width: 100%;
}

.main-contact-main {
  margin-bottom: 20px;
}

.part-head h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
}

.participants-heading {
  padding: 20px 12px;
}

.part-table thead {
  background: #eaeaea;
}

.part-table th {
  color: #fff;
  border: 0;
  font-size: 16px;
  background: transparent;
  font-weight: 400;
  padding: 14px 32px;
}

.part-table td {
  font-size: 13px;
  padding: 14px 32px;
}

.form-check-input {
  border-color: #a8a8a8;
  width: 18px;
  height: 18px;
}

.under-pool-form {
  margin-bottom: 45px;
}

.bidpool-project-info {
  border: 1px solid #a5a5a5;
}

.project-head {
  padding: 12px;
  background: #eaeaea;
}

.heading-red {
  font-size: 12px;
  color: #fd1818;
}

.info-main {
  gap: 24px;
  padding: 12px;
}

.info-main.form-field-cst .form-group {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.info-main.form-field-cst .form-group label {
  max-width: 30%;
  width: 100%;
}

.info-main.form-field-cst .form-group .form-control {
  max-width: 70%;
  width: 100%;
}

textarea.form-control.h-big {
  min-height: 217px;
}

.form-control::placeholder {
  color: #b4b4b4;
}

.bidpool-project-info {
  margin-bottom: 45px;
}

.start-pro {
  padding: 36px 32px;
}

.start-prov {
  padding: 10px 26px;
}

.start-pro.info-main.form-field-cst .form-group label {
  max-width: 45%;
}

.start-pro.info-main.form-field-cst .form-group .form-control {
  max-width: 55%;
}

.benefit-form {
  padding: 29px 22px;
}

.ben-form-field {
  background: #f0f0f0;
  padding: 15px;
}

.ben-form-field label {
  margin-bottom: 12px;
}

label {
  font-size: 14px;
  margin-bottom: 5px;
}

.ben-form-field label {
  color: #75bfe8;
}

.grey-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background: #77797a;
  color: #fff;
}

.ben-form-field label {
  color: #75bfe8;
  display: flex;
  align-items: center;
  gap: 10px;
}

.benefit-form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.milestones-main {
  padding: 30px 22px;
}

.gap-15 {
  gap: 15px;
}

.add-btn-amin {
  display: flex;
  padding: 21px 14px;
}

.add-btn-amin {
  display: flex;
  padding: 21px 14px;
  border-top: 1px solid #d9d9d9;
}

.mile-contact-form .part-table th,
.mile-contact-form .part-table td {
  padding: 12px 12px;
}

.cash-in-table .table.table-bordered {
  border: 1px solid #d9d9d9;
}

.cash-in-table input.form-control {
  max-width: 139px;
}

.cash-in-table .part-table th,
.cash-in-table .part-table td {
  padding: 12px;
}

.cash-in-table .table.table-bordered .part-table th,
.cash-in-table .table.table-bordered .part-table td {
  border: 1px solid #d9d9d9;
}

td.d-flex {
  border: 0 !important;
}

.milestone-para p {
  font-size: 12px;
}

.milestone-para {
  margin-bottom: 45px;
}

.btn-regular-blue.upload {
  font-size: 12px;
  align-items: center;
}

.supprt-doc {
  padding: 17px 22px;
}

.cash-in-table.support table {
  border: 1px solid #a5a5a5;
}

.main-btn-group {
  gap: 15px;
  margin-top: 22px;
}

.btn-regular-blue.dark {
  background: #0091ad;
}

.main-btn-group a {
  font-size: 13px;
  padding: 9px 22px;
}

.participants_main.m-b {
  margin-bottom: 45px;
}

.main-contact-form .milestone-para {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.main-border {
  border: 1px solid #a5a5a5;
  margin-top: 20px;
  padding: 10px 30px 30px;
}

.format-checkbox .form-check-label {
  font-size: 11px;
  margin-left: 10px;
}

.format-checkbox {
  margin-bottom: 20px;
}

.label-main {
  max-width: 286px;
  width: 100%;
}

.textarea-sec {
  max-width: calc(100% - 286px);
  width: 100%;
}

.label-main {
  padding: 27px 20px;
}

.textarea-sec {
  padding: 11px 23px;
  background: #f1f1f1;
}

.big-brief-section {
  border: 1px solid #a5a5a5;
}

.big-brief-section .brief-item {
  border-bottom: 1px solid #a5a5a5;
}

.main-border.second {
  margin-top: 68px;
}

.big-brief-section .brief-item:last-child {
  border-bottom: 0;
}

.brief-item label {
  font-size: 16px;
  font-weight: 600;
}

.main-border.second > .part-head {
  margin: 10px 0 20px;
}

.coordinate-table .part-table thead {
  background: #d7ebff;
}

.coordinate-table li {
  font-size: 13px;
}

.coordinate-table tr {
  border-top: 1px solid #a5a5a5;
}

.doc-upload {
  margin-bottom: 16px;
}

.big-border-section {
  margin-top: 66px;
  border: 1px solid #a5a5a5;
  padding: 35px 30px;
}

.cash-in-table.support.fix-table table {
  border: 0;
}

.cash-in-table.support.fix-table .part-table thead {
  background: #d7ebff;
}

.big-border-section .main-contact-main {
  margin: 0;
}

.bidpool-project-info.Reginal {
  margin-top: 50px;
}

.mile-para p {
  margin: 0;
  font-size: 16px;
}

.file-type {
  max-width: 24px;
  width: 100%;
}

.checkbox-part {
  padding: 22px;
  margin: 0;
}

/*--------------------------------------------------------------
## Calendar page style
--------------------------------------------------------------*/
.calendar-header {
  padding: 15px 10px;
  border-top: 1px solid #597590;
  border-bottom: 1px solid #597590;
}

.calendar-header .status span {
  border: 1px solid #afafaf;
  border-radius: 6px;
  color: #000;
  font-size: 14px;
  padding: 6px 10px;
  text-align: center;
}

.calendar-header .monthYear span {
  font-size: 18px;
  color: #000;
}

.profile-name {
  display: flex;
  border: 1px solid #afafaf;
  border-radius: 6px;
  padding: 6px 6px;
  justify-content: space-between;
  width: 70px;
  align-items: center;
}

.profile-name img {
  width: 20px;
  height: 20px;
}

.profile-name span {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  background: #d9d9d9;
  padding: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 1;
}

/* calendar-left side style */
.cal-left {
  background: #fafafa;
  padding: 10px;
}

.cal-left .schedule-btn button {
  background: #fff;
  border-radius: 60px;
  box-shadow: 1px 1px 8px 1px #cecece;
  padding: 6px 15px;
  font-size: 18px;
  border: 1px solid transparent;
}

.cal-left .schedule-btn button:hover {
  box-shadow: none;
  border: 1px solid #cecece;
}

.cal-left .schedule-btn button img {
  padding-right: 5px;
}

.meetwith h5 {
  font-size: 16px;
}

.meetwith .box-srh {
  background: #f1f1f1;
  padding: 5px 10px;
  display: flex;
}

.meetwith .box-srh input {
  background: #f1f1f1;
  padding: 5px;
  border: 1px solid transparent;
  margin-left: 8px;
  width: 100%;
  margin-bottom: 0;
}

.cal-picker {
  margin: 10px 0;
}

.btn {
  padding: 6px 10px;
  font-size: 16px;
}

.btn-save {
  background: #75bfe8;
  color: #fff;
}

.btn-cancel {
  background: #9d9e9e;
}

.testing {
  position: relative;
}

/*--------------------------------------------------------------
## Bidpool Applications page style start here
--------------------------------------------------------------*/
.page-content .title h2 {
  font-size: 32px;
  font-weight: 600;
  margin: 0;
}

.page-content ul.nav {
  border-bottom: 1px solid;
  border-color: #0091ad;
}

.page-content ul.nav .nav-item {
  padding: 0;
}

.page-content ul.nav .nav-item a.active {
  background-color: #0091ad;
  color: #fff;
  position: relative;
  top: 1px;
}

.title-bg {
  background: #77797a;
  margin: 15px 0;
  padding: 10px;
}

.title-bg h2 {
  color: #fff;
  font-size: 20px;
  margin: 0;
}

.inner-doc-file p {
  margin: 5px 0 0 0;
}

.inner-doc-file p span {
  display: block;
  border-top: 1px solid #acabab;
}

.main-contact-form-section {
  background: #fff;
  padding: 10px;
}

.main-contact-small-heading h4 {
  color: #0e3342;
  font-size: 20px;
  font-weight: bold;
}

/*--------------------------------------------------------------
##  Activity-log, In progress applications, Completed applications, Home Page - (RC Dashboard) page style start here
--------------------------------------------------------------*/

table.table.table-bg-even tbody tr:nth-child(even) {
  background: #eaeaea;
}

table.table.table-bg-even tbody tr:nth-child(odd) {
  background: transparent;
}

.homerc-dashboard {
  background-color: #fff;
  background-image: url(../assets/images/QWRAP_Logo-Long.png);
  background-size: 50%;
  background-position: top center;
  background-repeat: no-repeat;
}

table.table.table-bg-even {
  background: transparent;
}

.homerc-dashboard .main-contact-form-section {
  background: transparent;
}

.main-contact-add-btn.pr-3 {
  padding-right: 10px;
}

ul.nav .nav-item a img {
  height: 20px;
  margin-right: 2px;
}

.page-content ul.nav .nav-item a.active img {
  filter: invert(1);
}

.page-content ul.nav.bs-cstm .nav-item a.active img {
  filter: brightness(10);
}

.main-contact-header.application-header {
  background: transparent;
  padding: 0;
}

.page-content.homerc-dashboard {
  background-color: #fafafa;
}

.admin-view-main .form-group.col-sm-6 {
  margin: 0px -5px;
}

.application_head a {
  color: #fff;
  column-gap: 5px;
}

/*--------------------------------------------------------------
## Reviewer screen page style start here
--------------------------------------------------------------*/
.reviewer-div img {
  max-width: 100%;
}

.reviewer-div {
  padding-bottom: 70px;
}

/*--------------------------------------------------------------
## RC Contributors screen page style start here
--------------------------------------------------------------*/
.rc-alert-top {
  border: 1px solid #fcac0c;
  background: #f4f4f4;
  padding: 0px 0px 25px;
  margin-bottom: 30px;
}

.rc-alert-top h4 {
  background: #eaeaea;
  font-size: 20px;
  font-weight: 600;
  line-height: 25.2px;
  color: #000;
  column-gap: 8px;
  padding: 11px 30px;
}

.rc-alert-top p {
  font-size: 18px;
  font-weight: 500;
  line-height: 22.68px;
  margin-bottom: 0px;
  padding: 6px 30px 0px;
}

/*--------------------------------------------------------------
## Milestone Reporting screen page style start here
--------------------------------------------------------------*/
.milestone-top-main {
  background: #95ccbb;
  padding: 16.5px 20px;
}

.active-item {
  background: #fdfdfd;
  padding: 9px 9px;
}

.milestone-table thead {
  background: none;
  border-bottom: 1px solid #d9d9d9;
}

.milestone-table thead th {
  font-weight: 600;
  font-size: 14px;
}

.milestone-table td {
  font-size: 13px;
}

.edit-btn-new {
  border-top: 1px solid #d9d9d9;
  padding: 10px 80px;
}

.edit-btn-new a {
  display: inline-block;
  padding: 7px 22px;
}

/*--------------------------------------------------------------
## User View, Create New User,  style
--------------------------------------------------------------*/
.userWrap {
  max-width: 925px;
  width: 100%;
  background: rgba(241, 241, 241, 0.6);
  padding: 20px;
  margin: 50px auto 50px auto;
}

.hdr-border-bottom {
  border-bottom: 1px solid #597590;
}

/* --------------------------------------------------------------
 User View Edit Phone Verification style
 --------------------------------------------------------------*/
.two-factor {
  text-align: center;
  margin-top: 40px;
}

.two-factor .box-wrap {
  border: 1px solid #979494;
  border-radius: 3px;
  background: #fdfdfd;
  max-width: 340px;
  width: 100%;
  padding: 15px 30px;
  margin: 0 auto;
}

.two-factor .box-wrap form {
  display: flex;
  gap: 10px;
}

.two-factor .box-wrap input {
  border: 1px solid #979494;
  border-radius: 3px;
  background: #fdfdfd;
  width: 38px;
  height: 38px;
  text-align: center;
  margin: 3px;
}

.two-factor span {
  font-size: 12px;
  color: #7a7a7a;
  display: block;
  margin-top: 15px;
}

/* --------------------------------------------------------------
  Admin view Verification style
 --------------------------------------------------------------*/
.AdminWrap {
  max-width: 1080px;
  width: 100%;
  background: rgba(241, 241, 241, 0.6);
  padding: 20px;
  margin: 50px auto 50px auto;
}

.AdminWrap .hding {
  background: #e5e5e5;
  padding: 10px;
  margin-bottom: 20px;
}

.AdminWrap .hding h6 {
  font-size: 18px;
  margin: 0;
}

fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
  margin-top: 30px !important;
}

legend.scheduler-border {
  font-size: 14px !important;
  background: #f5f5f4;
  padding: 0 0 0 10px;
  margin-top: -12px;
  max-width: 80px;
}

.AdminWrap .multiselect {
  width: 100%;
}

.AdminWrap .selectBox {
  position: relative;
}

.AdminWrap .selectBox select {
  width: 100%;
  height: 37px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
}

.AdminWrap .overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.AdminWrap #checkboxes {
  display: none;
  border: 1px #dadada solid;
}

.AdminWrap #checkboxes label {
  display: flex;
  padding: 2px 10px;
}

.AdminWrap #checkboxes label input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.AdminWrap #checkboxes label:hover {
  background-color: #cccccc;
}

/* --------------------------------------------------------------
  Project family pages style
 --------------------------------------------------------------*/
.ProjectWrap {
  width: 100%;
  background: rgba(241, 241, 241, 0.6);
  padding: 20px;
}

.ProjectWrap h2.accordion-header button {
  font-size: 22px;
}

.highLight {
  display: flex;
  gap: 7px;
  align-items: center;
  margin-bottom: 10px;
}

.highLight .box {
  width: 16px;
  height: 16px;
  background: #94ffdb;
}

.highlightBg {
  background: #94ffdb !important;
}

.table-borderless {
  border: none !important;
  width: 100%;
}

.benefit-table {
  text-align: center;
}

.benefit-table2 {
  text-align: left;
}

.benefit-table span,
.benefit-table2 ul li {
  color: #2c7bc8;
}

.table-row-custom {
  background: #fff;
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px 0;
  font-weight: bold;
}

.line-box-wrap {
  border: 1px solid #a5a5a5;
}

.line-box-wrap .box-hding {
  border-bottom: 1px solid #a5a5a5;
  padding: 10px;
}

.line-box-wrap .box-hding span {
  font-size: 15px;
  font-weight: bold;
}

.line-box-wrap ul {
  list-style: none;
  margin: 0;
  padding: 10px;
}

.datenew .mdc-text-field--outlined .mat-mdc-form-field-infix,
.mdc-text-field--no-label .mat-mdc-form-field-infix {
  min-height: auto;
  padding: 0;
}
.datenew mat-error#mat-mdc-error-1 {
  font-size: 12px;
}

.datenew .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.kpi-weight {
  max-width: 100px;
}

.fundinmain .fundbtm {
  border-top: 1px solid #a5a5a5;
}

.fundinmain .fundbtm .form-control:focus {
  box-shadow: none;
}

.fundinmain .fundbtm .amtd {
  border-right: 1px solid #a5a5a5;
}

.fs-10 {
  font-size: 12px;
}
.cal-month-view .cal-cell.cal-has-events.cal-open {
  max-width: 14.3%;
  max-height: 100px;
  overflow: auto;
}

.cal-month-view .cal-cell.cal-has-events.cal-open::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: transparent;
}

.cal-month-view .cal-cell.cal-has-events.cal-open::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

.cal-month-view .cal-cell.cal-has-events.cal-open::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: #0000004d 0 0 6px inset;
  background-color: transparent;
}
