/************ Laptop Screen *************/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* Regional Coordinater */

  .part-table th,
  .part-table td,
  .brief-item label,
  .mile-para p,
  .bid-btn span {
    font-size: 14px;
  }

  .part-table th,
  .part-table td {
    padding: 10px 20px;
  }

  .big-border-section {
    margin-top: 40px;
  }

  .delete-btn {
    max-width: 20px;
  }

  .part-head h2 {
    font-size: 16px;
  }

  .bid-btn img {
    max-width: 20px;
  }

  /* Regional Coordinater */
}

/************ Laptop & Tab *************/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  header img.NextView-logo {
    width: 150px;
  }

  h2.title {
    font-size: 20px;
  }

  button.btn-adv {
    font-size: 14px;
  }

  .sorting-tabs ul li.nav-item span {
    font-size: 14px;
  }

  .survey-star .img-side img {
    width: 348px;
  }

  .survey-star .img-side .scan img {
    width: 148px;
  }

  .footer-wrap {
    text-align: center;
  }

  .check-wrap {
    flex-wrap: wrap;
  }

  .thankyou .social {
    gap: 10px;
    flex-direction: column;
  }

  .thankyou img.logo {
    width: 205px;
  }

  .thankyou span {
    font-size: 30px;
    line-height: 38px;
    margin-top: 13px;
  }

  /* Regional Coordinater */
  .part-table th,
  .part-table td,
  .brief-item label,
  .mile-para p,
  .bid-btn span {
    font-size: 14px;
  }

  .part-table th,
  .part-table td {
    padding: 10px 20px;
  }

  .big-border-section {
    margin-top: 40px;
  }

  .delete-btn {
    max-width: 20px;
  }

  .part-head h2 {
    font-size: 16px;
  }

  .bid-btn img {
    max-width: 20px;
  }

  .under-main-items {
    gap: 5px 15px;
  }

  .bid-menu-item,
  .main-contact-heading h2 {
    font-size: 14px;
  }

  .under-main-menu {
    padding: 14px 20px;
  }

  .main-border,
  .supprt-doc {
    padding: 15px;
  }

  .participants-heading {
    padding: 5px 10px 15px;
  }

  .main-contact-header {
    padding: 10px 15px;
  }

  .main-contact-form {
    padding: 25px 15px;
  }

  .form-field-cst {
    flex-direction: column;
  }

  .part-table th,
  .part-table td {
    padding: 12px 20px;
  }

  .form-field-cst .form-group label {
    margin-bottom: 5px;
  }

  .big-brief-section .brief-item {
    flex-direction: column;
  }

  .label-main {
    max-width: 100%;
    width: 100%;
    padding: 14px 15px;
  }

  .textarea-sec {
    max-width: 100%;
    width: 100%;
  }

  .textarea-sec {
    padding: 10px 20px;
  }

  .main-border.second {
    margin-top: 30px;
  }

  .main-border.second > .part-head {
    margin: 8px 0 15px;
  }

  .big-border-section {
    padding: 15px;
  }

  .milestones-main {
    padding: 20px 15px;
  }

  .start-prov {
    padding: 10px 15px;
  }

  .add-btn-amin {
    display: flex;
    padding: 15px;
    border-top: 1px solid #d9d9d9;
  }

  /* Regional Coordinater */

  /* New Bidpool */
  .info-main.form-field-cst .form-group {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .info-main.form-field-cst .form-group .form-control,
  .info-main.form-field-cst .form-group label,
  .start-pro.info-main.form-field-cst .form-group label,
  .start-pro.info-main.form-field-cst .form-group .form-control {
    max-width: 100%;
    width: 100%;
  }

  .start-pro,
  .benefit-form,
  .under-pool-form {
    padding: 15px;
  }

  .under-check .form-check .form-check-input {
    width: 22px;
    height: 22px;
  }

  .form-group.del-btn {
    position: static;
    width: 100%;
    text-align: right;
  }

  .part-table .table {
    width: 710px;
  }

  .main-btn-group {
    margin-top: 20px;
  }

  .grey-circle {
    width: 25px;
    height: 25px;
  }

  .benefit-form {
    gap: 15px;
  }

  .bidpool-project-info,
  .milestone-para,
  .under-pool-form {
    margin-bottom: 20px;
  }

  /* New Bidpool */
}

/************ Tab and Mobile *************/
@media (max-width: 840px) {
  .d-responsive {
    display: block !important;
  }

  .d-responsive .notBtn {
    justify-content: center;
    margin-top: 20px;
  }

  .select-box-wrap .select-box {
    width: 40%;
  }

  /* New Bidpool */
  .btn-regular-blue.upload img {
    width: 16px;
  }

  .form-group.del-btn {
    position: static;
    width: 100%;
    text-align: right;
  }

  /* New Bidpool */
}

/************ Tab and Mobile *************/
@media (max-width: 767px) {
  body,
  html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  .text-xs-center {
    text-align: center !important;
  }

  header img.qwrap-logo {
    max-width: 180px;
  }

  .phn-screen {
    text-align: center;
  }

  .phn-screen .justify-content-end {
    justify-content: center !important;
    margin-top: 15px !important;
  }

  /* Regional Coordinater */
  .part-table th,
  .part-table td,
  .brief-item label,
  .mile-para p,
  .bid-btn span,
  .bid-menu-item,
  .form-field-cst .form-group label,
  label {
    font-size: 13px;
  }

  .main-contact-heading h2 {
    font-size: 15px;
    font-weight: 500;
  }

  .under-main-items {
    gap: 5px 15px;
  }

  .form-field-cst,
  .big-brief-section .brief-item {
    flex-direction: column;
  }

  .content-wrap {
    max-width: calc(100% - 122px);
  }

  .bid-btn img,
  .delete-btn {
    max-width: unset;
    width: 20px;
  }

  .under-main-menu {
    padding: 15px;
  }

  .main-border {
    border: 0;
    padding: 0;
    margin-top: 15px;
  }

  .bid-btn {
    padding: 8px 11px;
  }

  .participants-heading {
    padding: 0px 0px 15px;
  }

  .main-contact-header,
  .part-table th,
  .part-table td,
  .textarea-sec,
  .start-prov {
    padding: 10px 15px;
  }

  .main-contact-form,
  .label-main,
  .add-btn-amin,
  .supprt-doc,
  .milestones-main {
    padding: 15px;
  }

  .participants_main.m-b {
    margin-bottom: 20px;
  }

  .part-head h2 {
    font-size: 14px;
  }

  .form-control {
    font-size: 12px;
    padding: 8px 10px;
  }

  .label-main,
  .textarea-sec {
    max-width: 100%;
    width: 100%;
  }

  .big-border-section,
  .main-border.second {
    margin-top: 20px;
    border: 0;
    padding: 0;
  }

  .bidpool-project-info.Reginal {
    margin-top: 20px;
  }

  .bidpool-project-info {
    margin-bottom: 20px;
  }

  .btn-regular-blue.upload {
    font-size: 10px;
  }

  .cash-in-table.support table {
    margin: 0;
  }

  .under-check .form-check .form-check-input {
    width: 20px;
    height: 20px;
  }

  .under-check .form-check-label {
    font-size: 13px;
  }

  .checkbox-part {
    padding: 15px;
    margin: 0;
  }

  .main-btn-group {
    margin-top: 30px;
  }

  .main-btn-group a {
    font-size: 11px;
    padding: 9px 15px;
  }

  .bidpool-top-main .d-flex {
    flex-wrap: wrap;
  }

  .main-btn-group.d-flex {
    flex-wrap: wrap;
  }

  /* Regional Coordinater */
  /* New Bidding */
  .info-main.form-field-cst .form-group {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .info-main.form-field-cst .form-group .form-control,
  .info-main.form-field-cst .form-group label,
  .start-pro.info-main.form-field-cst .form-group label,
  .start-pro.info-main.form-field-cst .form-group .form-control {
    max-width: 100%;
    width: 100%;
  }

  .start-pro,
  .benefit-form,
  .under-pool-form {
    padding: 15px;
  }

  .info-main {
    gap: 15px;
  }

  .ben-form-field {
    background: transparent;
    padding: 0;
  }

  .grey-circle {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  .under-pool-form {
    padding: 15px;
  }

  .under-pool-form {
    margin-bottom: 20px;
  }

  /* New Bidding */
  .welcome-wrap {
    width: auto;
    margin: 0 20px;
  }

  .extra-box,
  .userWrap {
    width: auto;
    margin: 20px;
  }

  h2.home-title {
    font-size: 22px;
  }

  .page-content .title h2,
  .bid-app-no h2 {
    font-size: 22px;
  }

  .ProjectWrap h2.accordion-header button {
    font-size: 16px;
    font-weight: 500;
  }

  img.igaq-logo {
    width: 290px;
  }

  .rc-alert-top h4 {
    font-size: 16px;
  }

  .rc-alert-top p {
    font-size: 14px;
  }

  .btn-regular-blue {
    font-size: 12px;
    white-space: nowrap;
  }
}

/************ All Mobile and small size screen *************/
@media (max-width: 575px) {
  /* .btn-regular-blue {
    display: inline-block;
  } */

  .sideBar {
    display: none;
  }

  .content-wrap {
    margin-left: 0;
  }

  .nav-phn {
    display: block;
    position: relative;
    z-index: 999;
  }

  .nav-phn img.phn-toggle {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 20px;
  }

  /* Regional Coordinater */
  .content-wrap {
    max-width: 100%;
  }

  .btn-regular-blue {
    font-size: 12px;
  }

  .milestone-para p {
    font-size: 10px;
    margin: 0;
  }

  .part-table table.table {
    width: 650px;
  }

  .bid-btn img,
  .delete-btn {
    max-width: unset;
    width: 17px;
  }

  .participants-heading,
  .main-contact-header {
    flex-wrap: wrap;
    gap: 15px;
  }

  /* Regional Coordinater */
  .page-content .title h2 {
    font-size: 24px;
  }

  .rc-alert-top h4 {
    font-size: 16px;
  }

  .rc-alert-top p {
    font-size: 14px;
  }

  .refresh-wrap {
    margin-top: 15px;
  }

  .btn-darkGreen,
  .btn-cancel {
    font-size: 13px;
  }
}

/************ All Mobile and small size screen *************/
@media (max-width: 479px) {
  .page-content .title h2 {
    font-size: 22px;
  }
}
