$theme-colors: (
  // "primary": #073342,
  // "green": #8bc53f,
);

/* Importing Custom Bootstrap CSS file */
@import "styles/custom-style";
@import "styles/custom-responsive";
@import "styles/styles.css";

/* Importing Application files */
@import "./styles/global";
@import "./styles/angular-material";
