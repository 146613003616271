h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1,
p,
ul {
  margin-bottom: 0;
}

.form-control {
  border-radius: 3px;
  border: 1px solid #dedede !important;
  background: #f5f5f5 !important;
}

.img {
  max-width: 100%;
}

.logpg {
  background: url("../assets/images/QWRAP_Logo-Long-rgb2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100vh;
}

.forgetwrap {
  max-width: 730px;
  width: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 20px;
  margin: 0 auto;
}

.forgetwrap .forinr {
  max-width: 454px;
  margin: auto;
}

.forgetwrap .forinr p {
  font-size: 15px;
}

.gbck {
  max-width: 730px;
  width: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 20px;
  margin: 0 auto;
  margin-top: 40px;
}

.gbck span {
  font-size: 18px;
}

header.login-hdr img {
  width: 253px;
  height: 92px;
  object-fit: contain;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  box-shadow: none !important;
}

.form-control::placeholder {
  color: #666;
}

.form-control.is-invalid:focus {
  box-shadow: none;
}

.text-primary {
  color: #0091ad;
}

.text-grn {
  color: #3c9;
}

.btn-primary-new {
  background: #0091ad;
  color: #fff;
  font-size: 18px;
  padding: 8px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

ul.nav .nav-item a.active {
  color: #33cc99;
}

.btn-primary-new:hover {
  background: #0090add7;
  color: #fff;
}

.savebtn {
  border-radius: 4px;
  background: #33cc99;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #fff;
  padding: 10px 22px;
  font-size: 16px;
}

.secondbtn {
  border-radius: 4px;
  background: #9d9e9e;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #fff;
  padding: 10px 22px;
  font-size: 16px;
}

.createbtn {
  border-radius: 4px;
  background: #75bfe8;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #fff;
  padding: 10px 22px;
  font-size: 16px;
}

.maintbcon {
  border: 1px solid #77797a;
}

.maintbcon h2 {
  background: #77797a;
  color: #fff;
  padding: 16px 15px;
}

.maintbcon .maintbs {
  padding: 24px 36px;
}

.user-form-1 form .mat-mdc-form-field,
.user-form-2 .mat-mdc-form-field {
  display: block;
}

.user-form-2 form {
  padding: 20px;
}

.user-form-1 {
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-gap: 60px;
}

.refresh-wrap .text-side input::placeholder {
  color: #898888;
}

.page-content {
  background: url("../assets/images/QWRAP_Logo-Long-rgb2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-height: calc(100vh - 115px);
}

.user-form-1 .form-innerdatas {
  background: rgba(241, 241, 241, 0.5);
}

.user-form-2 {
  background: rgba(241, 241, 241, 0.5);
}

.user-form-1 .form-innerdatas h2 {
  padding: 20px;
  font-size: 20px;
  color: #000;
  background: rgba(229, 229, 229, 0.5);
}

.user-form-1 .form-innerdatas form {
  padding: 20px;
}

.modnf .mat-mdc-form-field {
  width: 100%;
}

.modal-header .btn-close {
  background: #000 url("../assets/images/closeicon.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  padding: 7px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.modal-header .btn-close svg {
  color: #fff;
}

/* input css start */

.newinputs .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
.newinputs .mat-mdc-select {
  border-radius: 3px;
  border: 1px solid #110e0e;
  background: #fdfdfd;
  padding: 10px 12px;
  font-size: 13px;
}

.newinputs .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control::placeholder {
  font-size: 13px;
  color: #000 !important;
}

.newinputs .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background: transparent;
  padding: 0;
  outline: none !important;
  border-radius: -2px;
}

.newinputs .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  position: static;
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
}

.newinputs .mat-mdc-form-field-infix {
  padding: 0;
}

.newinputs .mdc-line-ripple,
.newinputs .mat-mdc-form-field-focus-overlay {
  display: none;
}

.newinputs .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  font-size: 14px !important;
  color: #000 !important;
  margin-bottom: 8px;
}

.newinputs .mat-mdc-select-arrow svg {
  transform: translate(-50%, -14%);
  -webkit-transform: translate(-50%, -14%);
  -moz-transform: translate(-50%, -14%);
  -ms-transform: translate(-50%, -14%);
  -o-transform: translate(-50%, -14%);
}

/* input css end */

.calneins .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.calneins .mat-mdc-form-field-infix {
  padding-top: 0;
  padding-bottom: 0;
  min-height: auto;
  width: 100px;
}

.calneins .mat-mdc-select-arrow svg {
  transform: translate(-50%, -45%);
}

.calneins .mat-mdc-select {
  padding: 6px 6px;
  border: 1px solid #afafaf;
}

.edbtnnew {
  position: absolute;
  right: 0;
  top: 32px;
}

.edbtnnew button {
  padding: 0 !important;
  margin: 0 10px 0 0 !important;
}

.welcome-wrap form .otfombg {
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #f5f5f5;
  padding: 20px 40px;
  margin-top: 20px;
}

.welcome-wrap form .otfombg .otdtl {
  color: #5c5c5c;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}

.welcome-wrap form .otfombg .form-control {
  border-radius: 3px;
  border: 1px solid #979494 !important;
  background: #fdfdfd !important;
}

.newsowb .btn-primary-new {
  font-size: 14px;
}

.newformmodal .mat-mdc-form-field {
  width: 100%;
}

/* date picker */

.date-picker {
  position: relative;
}

.date-picker .ngb-dp-arrow-prev {
  position: absolute;
  right: 30px;
  height: auto;
  display: block;
  width: auto;
}

.date-picker .ngb-dp-arrow-next {
  position: absolute;
  right: 13px;
  display: block;
  height: auto;
  width: auto;
}

.date-picker .ngb-dp-header {
  position: relative;
}

.date-picker .ngb-dp-navigation-select {
  padding-left: 15px;
}

.date-picker ngb-datepicker-navigation-select > .form-select {
  width: auto;
  flex: none;
  line-height: normal;
  padding: 0;
  border: none;
  background: none;
  height: auto;
}

.date-picker ngb-datepicker-navigation-select > .form-select:focus {
  box-shadow: none;
}

.date-picker .ngb-dp-arrow-btn {
  padding: 0;
  margin: 0;
}

.date-picker .ngb-dp-navigation-chevron {
  color: #000;
  width: 10px;
  border-width: 0.17em 0.17em 0 0;
  height: 10px;
}

.date-picker .ngb-dp-week {
  justify-content: space-between;
}

.date-picker .ngb-dp-months {
  display: block;
}

.date-picker .ng-valid {
  width: 100%;
  border: none;
}

.date-picker .bg-primary {
  background-color: #0091ad !important;
}

.date-picker .ng-star-inserted.bg-primary {
  border-radius: 50%;
}

.cal-month-view .cal-event {
  background-color: #0091ad;
}

/* two auth */
.twowrpauth .inrblck h5 {
  margin-bottom: 20px;
}

.twowrpauth .inrblck .code-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 336px;
  margin: auto;
  padding: 20px;
  border-radius: 3px;
  border: 1px solid #979494;
  background: #fdfdfd;
  margin-bottom: 20px;
}

.twowrpauth .inrblck .code-input-container .mat-mdc-form-field {
  width: 38px;
}

.twowrpauth
  .inrblck
  .code-input-container
  .mat-mdc-form-field
  .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.twowrpauth .inrblck .code-input-container .mat-mdc-form-field .mat-mdc-form-field-infix {
  display: block;
  padding: 0;
  min-height: auto;
}

.twowrpauth
  .inrblck
  .code-input-container
  .mat-mdc-form-field
  .mat-mdc-form-field-infix
  .mat-mdc-form-field-input-control {
  text-align: center;
  border-radius: 3px;
  background: #fdfdfd;
  padding: 7px;
}

.twowrpauth .inrblck .twopergh {
  color: #7a7a7a;
  margin-bottom: 20px;
}

/* two auth */

.meetwith .mtinr {
  background: #f1f1f1;
  padding: 8px 10px;
}

.meetwith .mtinr .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.meetwith .mtinr .mat-mdc-text-field-wrapper {
  background: transparent !important;
  padding: 0;
}

.meetwith .mtinr .mat-mdc-text-field-wrapper .mdc-line-ripple {
  display: none;
}

.meetwith .mtinr .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  min-height: auto;
  height: auto;
  padding: 0;
}

.meetwith .mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
  background: transparent;
}

.meetwith .mat-mdc-form-field {
  width: 100%;
}

.approved {
  background-color: #d1e7dd;
  padding: 1rem 0.5rem;
}
