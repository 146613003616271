button {
  border: none;
  background-color: initial;
}

.table {
  margin-bottom: 0;
  vertical-align: initial;
}

.form-control {
  background-color: initial;
}

.toaster-fixed {
  position: fixed;
  top: 12px;
  right: 12px;
}

.autocomplete-btn {
  --mdc-filled-text-field-container-color: #75bfe8;
  cursor: pointer;

  .mdc-text-field {
    background-color: #75bfe8;

    mat-label {
      color: #fff;
      font-weight: bold;
    }
  }
}

// Override custom-style.css
.part-table table thead {
  background: #77797a;
}
